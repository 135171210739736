.container {
  max-width: 100vw;
}

.contentLeft {
  background: #f6f6f6;
  flex-basis: 64%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 90px 130px 125px;
  background: transparent, linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
}

.contentRight {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 36%;
  justify-content: center;

  min-height: 100vh;
  height: 100%;
  backdrop-filter: blur(14px);
  position: fixed;
  right: 0;
  width: 36%;
  // max-width: 490px;
  top: 0;
  fill: #f6f6f6;
  color: #f6f6f6;
}

.logo {
  & > svg {
    margin-bottom: 32px;
    width: auto;
    height: 88px;
  }
}

.contentRightContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 55px;
  max-width: 490px;
  align-items: center;
  transition: padding ease 0.3s;
  justify-content: flex-start;
  height: 490px;

  & > div:first-child {
    align-items: center;

    & > div:last-child {
      margin-top: 90px;
      width: 80%;
    }
  }
}

.menu {
  flex-basis: 36%;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  min-height: auto;
  z-index: 1;
  position: fixed;
  top: 50px;
  backdrop-filter: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.copyright {
  position: absolute;
  bottom: 17px;
  font-family: Arial;
  font-size: 14px;
  color: #593a7c;
}

@media screen and (max-width: 768px) {
}
