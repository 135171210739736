.userAgreement {
  margin: 20px 32px;
  font-family: 'Montserrat';
  font-style: normal;

  &__title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: #593a7c;
    margin: 20px 0;
  }

  & > p {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
  }

  &__listFirstLevel {
    & > * + * {
      margin-top: 32px !important;
    }
  }

  &__list {
    & > li {
      list-style-type: decimal;
      list-style-position: outside;
    }

    & li {
      margin-top: 4px;
    }

    counter-reset: item;
    & li {
      display: block;
    }

    li:before {
      display: inline-block;
      content: counters(item, '.') '. ';
      counter-increment: item;
      font-weight: 500;
      margin-right: 8px;
    }

    &Flex {
      & li {
        display: flex;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__logo {
    display: block;
    top: 32px;

    @media screen and (min-width: 768px) {
      margin-right: 32px;
    }
  }

  &__date {
    margin-left: 82px;
    margin-top: 48px;
  }
}
