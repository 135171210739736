.contentLeftTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 110px;
  & > svg {
    width: auto;
    height: 88px;
  }
}

.address {
  position: relative;
  display: flex;
  background: #ffffff29 0% 0% no-repeat padding-box;
  border-radius: 6px;
  backdrop-filter: blur(4px);
  padding: 11px 19px;
  font-family: Raleway;
  font-weight: normal;
  align-items: center;
  min-width: 272px;
  cursor: pointer;

  & > div {
    width: 100%;
  }

  & p {
    text-align: center;
  }

  & .selector-list {
    background: #ffffff29 0% 0% no-repeat padding-box;
    border-radius: 6px;
    backdrop-filter: blur(4px);
    padding: 11px 19px;
  }

  & .selector-element.active {
    background: #ffffff40 0% 0% no-repeat padding-box;
  }

  & > svg {
    margin-right: 8px;
  }
}

.citySwitcher {
  position: absolute;
  top: 45px;
  left: 185px;
  padding: 0;
  max-width: 100px;
  cursor: pointer;

  & span {
    font-family: Raleway;
    font-size: 12px;
    text-align: right;
    padding: 0;

    &::after {
      position: absolute;
      left: 0px;
      bottom: 2px;
      height: 1px;
      content: '';
      width: 86px;
      background-color: #f6f6f6;
    }
  }
}

@media screen and (max-width: 980px) {
  .contentLeftTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .contentLeftTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 55px;

    gap: 10px;

    padding: 5px 10px;
    background-color: #f5f5f5;
    color: #000000;
  }

  .weatherInDegrees {
  }

  .logo {
    width: 50px;
    & > svg {
      width: 50px;
      height: auto;
    }
  }

  .citySwitcher {
    display: none;
  }

  .address {
    background: none;
    min-width: fit-content;

    & > p {
      text-decoration: underline;
    }

    & > svg {
      fill: #593a7c;
    }
  }
}
