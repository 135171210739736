.root {
  align-items: center;
  justify-content: center;
  font-family: 'Raleway';
  position: relative;
}

.link {
  position: relative;
  max-width: 300px;
  z-index: 1;
  border-radius: 8px;
  padding: 0px 20px;
  color: black;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.activeLink {
  color: white;
  padding: 6px 20px;
  border-radius: 8px;
  background: #593a7c;
}

.activeHover {
  &:hover {
    color: #593a7c;
  }
}
