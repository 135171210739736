.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
}

.leftSide {
  flex-basis: 64%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 90px 130px 125px;
  background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
}

.rightSide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 36%;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  backdrop-filter: blur(14px);
  align-items: center;
}

.contentLeft {
  color: #f6f6f6;
  fill: #f6f6f6;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 110px;
  & > svg {
    width: auto;
    height: 88px;
  }
}

.contentRightContainer {
  padding: 0px 55px;
  max-width: 490px;
  align-items: left;
  transition: padding ease 0.3s;
  height: 490px;
}

.menu {
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  min-height: auto;
  z-index: 1;
  position: absolute;
  top: 50px;
  backdrop-filter: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.copyright {
  position: absolute;
  bottom: 17px;
  font-family: Arial;
  font-size: 14px;
}

.gismeteoLink {
  position: absolute;
  bottom: 17px;
  font-family: Arial;
  font-size: 14px;
  color: inherit;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 980px) {
  .leftSide {
    padding: 50px 30px 130px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .contentRightContainer {
    padding: 0px 10px;
    align-items: left;
    transition: padding ease 0.3s;
  }

  .leftSide {
    flex-basis: 100%;
    padding: 0;
    min-height: 250px;
    background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
  }

  .rightSide {
    flex-basis: 100%;
    flex-direction: column;
    justify-content: start;
    backdrop-filter: none;
    align-items: center;
    min-height: calc(100vh - 300px);
    background: #f6f6f6;
  }

  .logo {
    width: 50px;
    & > svg {
      width: 50px;
      height: auto;
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    margin-left: auto;
    margin-right: auto;
    height: 55px;
    width: 100%;
    min-height: auto;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6px);
  }

  .copyright {
    position: relative;
    bottom: 5px;
    margin-bottom: 10px;
    color: #f5f5f5;
    font-weight: 300;
  }

  .gismeteoLink {
    position: relative;
    bottom: 25px;
    color: #f5f5f5;
  }
}
