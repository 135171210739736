@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./Raleway/Raleway-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./Raleway/Raleway-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'San-francisco';
    src: url('./SanFrancisco/SFUIText-Regular.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'San-francisco';
    src: url('./SanFrancisco/SFUIDisplay-Semibold.woff');
    font-weight: 600;
    font-style: normal;
}