@import './Assets/Styles/Reset.sass';
@import './Assets/fonts/font.css';
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-size: 16px;
    color: #141F33;
    background: rgba(246, 246, 246, 1); }

.flex-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; }

.flex-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.btn-purple {
    border-radius: 4px;
    background: linear-gradient(283deg, #736C98 0%, #593A7C 100%);
    color: white;
    border: none;
    height: 40px;
    max-width: 171px;
    width: 100%;
    cursor: pointer;
    font-size: 15px;

    &:hover {
        background: white;
        color: #593A7C;
        border: 1px solid #593A7C; } }

.content-left {
    flex-basis: 64%;
    height: 100%;
    min-height: 100vh;
    padding: 50px 90px 130px 125px;
    background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%); }

.content-right {
    flex-basis: 36%;
    justify-content: center;
    // min-width: 490px
    min-height: 100vh;
    height: 100%;
    backdrop-filter: blur(14px);
    align-items: center; }

@media screen and (max-width: 980px) {
    .content-left {
        padding: 50px 30px 130px 30px; } }

.copyright {
    position: absolute;
    bottom: 17px;
    font-family: Arial;
    font-size: 14px; }

.gismeteo-link {
    position: absolute;
    bottom: 17px;
    font-family: Arial;
    font-size: 14px;
    color: inherit;
    opacity: 0.8;

    &:hover {
        opacity: 1; } }




