.button {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #593a7c;
  min-width: 350px;
  padding: 15px 30px;
  cursor: pointer;
}
