.confidentialityPolicy {
  margin: 20px 32px;
  font-family: 'Montserrat';
  font-style: normal;

  &En {
    margin: 0 auto;
    max-width: 800px;

    & li {
      list-style: decimal;
    }

    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 21px;
    text-transform: uppercase;
    color: #593a7c;
    margin: 20px 0 32px 0;
    text-align: center;
  }

  &__logo {
    display: block;
    position: relative;
    top: 32px;

    @media screen and (max-width: 768px) {
      margin: 0 auto 64px;
    }
  }

  & p,
  & li {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
  }
  
  &__date {
    margin-top: 4rem !important;
  }
}
