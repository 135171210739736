.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
}

.rightColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftSide {
  flex-basis: 64%;
  height: 100%;
  min-height: 100vh;
  padding: 50px 90px 130px 125px;
  background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
}

.rightSide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 36%;
  justify-content: center;
  // min-width: 490px;
  min-height: 100vh;
  height: 100%;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  align-items: center;
}

.contentLeft {
  color: #f6f6f6;
  fill: #f6f6f6;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.contentLeftTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 110px;
  & > svg {
    width: auto;
    height: 88px;
  }
}

.address {
  position: relative;
  display: flex;
  background: #ffffff29 0% 0% no-repeat padding-box;
  border-radius: 6px;
  backdrop-filter: blur(4px);
  padding: 11px 19px;
  font-family: Raleway;
  font-weight: normal;
  align-items: center;
  min-width: 272px;
  cursor: pointer;

  & > div {
    width: 100%;
  }

  & p {
    text-align: center;
  }

  & .selector-list {
    background: #ffffff29 0% 0% no-repeat padding-box;
    border-radius: 6px;
    backdrop-filter: blur(4px);
    padding: 11px 19px;
  }

  & .selector-element.active {
    background: #ffffff40 0% 0% no-repeat padding-box;
  }

  & > svg {
    margin-right: 8px;
  }
}

.citySwitcher {
  position: absolute;
  top: 45px;
  left: 185px;
  padding: 0;
  max-width: 100px;
  cursor: pointer;

  & span {
    font-family: Raleway;
    font-size: 12px;
    text-align: right;
    padding: 0;

    &::after {
      position: absolute;
      left: 0px;
      bottom: 2px;
      height: 1px;
      content: '';
      width: 86px;
      background-color: #f6f6f6;
    }
  }
}

.contentLeftBottom {
  height: auto;
  justify-content: 'space-between';
  align-items: 'flex-end';

  & p {
    position: relative;
    font-size: 92px;
    font-family: 'San-Francisco';
    font-weight: bold;
    text-shadow: 0px 0px 9px #0000004a;

    & sup {
      position: absolute;
      font-size: 25px;
      top: 10px;
    }
  }
}

.contentBottom {
  justify-content: center;
  height: 30px;
  margin-top: 20px;
}

.sunBlock {
  display: flex;
  margin-right: 10px;
  font-family: 'Montserrat';
}

.sun {
  background: #ffffff9c 0% 0% no-repeat padding-box;
  border-radius: 6px;
  backdrop-filter: blur(4px);
  font-size: 13px;
  color: #141f33, 0.89;
  padding: 7px;
  display: flex;

  & > svg {
    margin-right: 8px;
  }

  &:first-child {
    margin-right: 10px;
  }
}

.contentRightContainer {
  padding: 0px 55px;
  max-width: 490px;
  align-items: left;
  transition: padding ease 0.3s;
  height: 490px;
}

.contentRightContainerWeek {
  padding: 0px 20px;
}

.currentTemperature {
  display: flex;
  align-items: center;
}

.weatherMore {
  display: flex;
  margin-left: 50px;
  align-items: flex-start;
  flex-direction: column;

  & svg {
    width: 74px;
    height: 60px;
    object-fit: contain;
  }

  & p {
    font-size: 17px;
    font-family: Raleway;
  }
}

.menu {
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  min-height: auto;
  z-index: 1;
  position: absolute;
  top: 50px;
  backdrop-filter: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.copyright {
  position: absolute;
  bottom: 17px;
  font-family: Arial;
  font-size: 14px;
}

.gismeteoLink {
  position: absolute;
  bottom: 17px;
  font-family: Arial;
  font-size: 14px;
  color: inherit;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 980px) {
  .leftSide {
    padding: 50px 30px 130px 30px;
  }

  .contentLeftTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .bottomBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.61) 0% 0% no-repeat padding-box;
    padding-bottom: 15px;
    border-radius: 0 0 9px 9px;
  }

  .contentRightContainer {
    padding: 0px 10px;
    align-items: left;
    transition: padding ease 0.3s;
  }

  .leftSide {
    flex-basis: 100%;
    padding: 0;
    min-height: 250px;
    background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
  }

  .rightSide {
    flex-basis: 100%;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: none;
    align-items: center;
    min-height: auto;
    background: transparent linear-gradient(90deg, rgba(38, 60, 84, 0.59) 0%, rgba(22, 38, 56, 0.59) 100%);
  }

  .contentLeftTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 55px;

    gap: 10px;

    padding: 5px 10px;
    background-color: #f5f5f5;
    color: #000000;
  }

  .weatherInDegrees {
  }

  .languageSwithcer {
    position: relative;
  }

  .logo {
    width: 50px;
    & > svg {
      width: 50px;
      height: auto;
    }
  }

  .citySwitcher {
    display: none;
  }

  .address {
    background: none;
    min-width: fit-content;

    & > p {
      text-decoration: underline;
    }

    & > svg {
      fill: #593a7c;
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    margin-left: auto;
    margin-right: auto;
    height: 55px;
    width: 100%;
    min-height: auto;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6px);
  }

  .copyright {
    position: relative;
    bottom: 5px;
    margin-bottom: 10px;
    color: #f5f5f5;
    font-weight: 300;
  }
  
  .gismeteoLink {
    position: relative;
    bottom: 25px;
    color: #f5f5f5;
  }
}
