.userAgreement {
  margin: 20px 32px;
  font-family: 'Montserrat';
  font-style: normal;

  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 21px;
    text-transform: uppercase;
    color: #593a7c;
    margin: 20px 0;
    text-align: center;
  }

  & p {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
  }

  & div {
    margin: 15px 0;
    font-weight: 500;
    font-size: 16px;
  }
}

.instructions {
  & > div {
    margin: 35px 0;
    font-weight: 500;
    font-size: 16px;
  }

  & > section {
    margin-top: 64px;
  }

  &__logo {
    width: 150px;
    height: 150px;

    @media screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  &__settings {
    display: flex;
    justify-content: center;

    & img {
      max-width: 400px;
      height: auto;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__mainscreen {
    display: flex;
    justify-content: center;

    & img {
      max-width: 600px;
      height: auto;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__search {
    display: flex;
    justify-content: center;

    & img {
      max-width: 400px;
      min-width: 300px;
      height: auto;
      min-height: 60vh;
      max-height: 80vh;
      object-fit: cover;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__icons {
    display: flex;
    justify-content: center;

    & img {
      width: 300px;
      height: auto;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__periods {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 128px;
    justify-content: center;

    & img {
      max-width: 400px;
      width: 30vw;
      height: auto;
      margin: 32px 0;
    }

    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      & img {
        width: 80vw;
      }
    }
  }

  &__marker {
    display: flex;
    justify-content: center;

    & img {
      width: 600px;
      height: auto;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__cats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    & > div {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }
    }

    & img {
      width: 200px;
      height: auto;
      margin: 32px 0;
    }
  }

  &__weatherDetails {
    display: flex;
    justify-content: center;

    & img {
      width: 600px;
      height: auto;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__background {
    display: flex;
    justify-content: center;

    & img {
      width: 300px;
      height: auto;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__subscriptionButton {
    display: flex;
    justify-content: center;

    & img {
      width: 200px;
      height: auto;
      margin: 32px 0;

      @media screen and (max-width: 768px) {
        width: 100px;
        height: auto;
      }
    }
  }

  &__subscriptionEn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 32px auto !important;
    padding: 16px 32px;
    max-width: 640px;
    text-align: center;
    border: 1px solid #593a7c;

    background: no-repeat center center url(/Assets/instructions/en/logo.svg);

    &_text {
      margin: 0;
      font-size: 1.2rem !important;
      font-family: 'Cambria', 'Montserrat';

      & > strong {
        color: #593a7c;
      }

      @media screen and (max-width: 768px) {
        font-size: 1rem !important;
      }
    }

    & > div {
      margin: 0;
    }
  }

  &__list {
    & > li {
      list-style: none;
    }
  }

  &__footer {
    margin-top: 3rem;

    &::before {
      content: '';
      display: block;
      width: 50%;
      height: 2px;
      background-color: #593a7c;
      margin: 0 auto;
    }

    & p {
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
      color: #593a7c;
      margin: 20px 0;
      text-align: center;
    }
  }
}
